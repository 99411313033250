import tw, { styled } from "twin.macro"
import { CatalogueGridCard } from "./Card/CatalogueGridCard"

export const Wrapper = tw.div`flex flex-wrap items-start -m-0-6 md:-m-1-vw xl:-m-1-2`

export const Row = styled.div`
  ${({ sticky }) => (sticky ? tw`md:sticky md:top-2` : null)}
  ${tw`w-full md:w-1/2 flex flex-wrap md:p-0.5-vw xl:p-0-6`}
`

export const Card = styled(CatalogueGridCard)`
  ${({ size }) => {
    switch (size) {
      case "1/2":
        return tw`w-full p-0-6 md:p-0.5-vw xl:p-0-6`
      case "1/3":
        return tw`w-full md:w-1/3 p-0-6 md:p-0.5-vw xl:p-0-6`
      case "1/4":
        return tw`w-full p-0-6 md:p-0.5-vw xl:p-0-6`
      case "1/8":
        return tw`w-full md:w-1/2 p-0-6 md:p-0.5-vw xl:p-0-6`
      case "1/9":
        return tw`w-full md:w-1/3 p-0-6 md:p-0.5-vw xl:p-0-6`
      case "2/9":
        return tw`w-full md:w-1/3 p-0-6 md:p-0.5-vw xl:p-0-6`
      case "1/12":
        return tw`w-full md:w-1/3 p-0-6 md:p-0.5-vw xl:p-0-6`
      default:
        break
    }
  }}
`
