import React from "react"

import { withCatalogueGrid } from "./withCatalogueGrid"
import { Wrapper, Row, Card } from "./CatalogueGridStyles"

export const CatalogueGrid = withCatalogueGrid(
  ({
    leftCards,
    rightCards,
    handlePromoClick,
    isLeftSticky,
    isRightSticky,
  }) => (
    <Wrapper>
      <Row sticky={isLeftSticky}>
        {leftCards?.map(card => (
          <Card
            key={card._key}
            handlePromoClick={handlePromoClick}
            card={card}
            size={card.size}
          />
        ))}
      </Row>
      <Row sticky={isRightSticky}>
        {rightCards?.map(card => (
          <Card
            key={card._key}
            handlePromoClick={handlePromoClick}
            card={card}
            size={card.size}
          />
        ))}
      </Row>
    </Wrapper>
  ),
)

export default CatalogueGrid
