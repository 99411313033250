import React from "react"

const getArea = cards =>
  cards?.reduce((acc, cur) => {
    const [numerator, denominator] = cur.size.split("/")
    return acc + numerator / denominator
  }, 0)

export const withCatalogueGrid = Component => ({
  name = "CatalogueGrid",
  leftCards,
  rightCards,
}) => {
  const isLeftSticky = getArea(rightCards) > 0.5

  const isRightSticky = getArea(leftCards) > 0.5

  Component.displayName = name
  return (
    <Component
      leftCards={leftCards}
      rightCards={rightCards}
      isLeftSticky={isLeftSticky}
      isRightSticky={isRightSticky}
    />
  )
}
